<template>
  <div class="payorder">
    <title-top>订单详情</title-top>
    <div class="row between align-center font32 f-white bg-41ABA7 h210">
      <span class="ml25">{{res.state_zh_cn}}</span>
      <img class="mr50 bgimg" src="~images/car.png" alt="">
    </div>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="row between align-center bg-white h210">
      <div class="column font24 f-333333 ml25">
        <div class="row mb20">
          <span>{{name}}</span>
          <span class="ml40">{{mobile}}</span>
        </div>
        <div class="row align-center">
          <img class="imgsize32" src="~images/dingwei.png" alt />
          <span class="f-999999">{{address}}</span>
        </div>
      </div>
    </div>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="row between align-center bg-white h210 mb20" v-for="(item,index) in res.items" :key="index">
      <img class="imgsize176 br4 ml25" :src="item.image_url" alt />
      <div class="flex1 column font24 f-1A1A1A ml16">
        <p class="ellipsis mr40">{{item.product_name}}</p>
        <div class="row between f-999999 mt16 align-center">
          <div class="row align-center" v-for="(spac,x) in item.specification_values" :key="x">
            <span class="font24 f-999999 mr16">{{spac.content}}</span>
          </div>
          <span class="mr25">×{{item.quantity}}</span>
        </div>
      </div>
    </div>
    <div class="row between align-center bg-white h96 font28 f-1A1A1A mb20">
      <span class="ml25">商品金额</span>
      <span class="mr25 fontweight600">￥{{res.total_payment_amount}}</span>
    </div>
    <div class="row align-center font24 f-999999 bg-white h96">
      <img class="imgsize32 ml25 mr16" src="~images/kefu.png" alt="">
      <span>联系客服</span>
    </div>
    <div class="column just-center bg-white font24 f-999999 h146">
      <span class="ml25 mb20">订单编号：{{res.number}}</span>
      <span class="ml25">创建时间：{{res.created_at | formatDate}}</span>
    </div>
    <div class="line"></div>
    <div style="width:100%;height:80px;"></div>
    <div class="row flex-end font28 f-999999 bg-FCFCFC h118 w100 position_f" style="bottom:0;max-width:750px;">
      <span class="row flex-center br8 opertate mr30">退换详情</span>
      <span class="row flex-center br8 opertate mr25">已完成</span>
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {orderDetail} from 'network/igou2'
import { formatDate } from "common/util";
export default {
  name: "OrderDetail",
  data() {
    return {
      order_id:this.$route.query.order_id,
      res:{},
      name:'',
      mobile:'',
      address:''
    };
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formatHs(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm");
    },
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },
  mounted() {
    if(!this.$route.query.order_id){
      this.$toast.show('参数错误');
      this.$router.back();
      return;
    }
    this._orderDetail(this.$route.query.order_id);
  },
  methods:{
    _orderDetail(id){
      orderDetail(id).then(res=>{
        console.log(res)
        
        this.res = res;
        /*
        this.address = res.shipping_address;
        this.mobile = res.shipping_mobile;
        this.name = res.shipping_contact_name 
        */
        if(address.split(" ").length == 3){
          this.name = address.split(" ")[0];
          this.mobile = address.split(" ")[1];
          this.address = address.split(" ")[2];
        }else{

        }
        
        

      })
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.bgimg {
  width: 2.666667rem;
  height: 2.346667rem;
}
.opertate{
  border: 1px solid #666666;
  padding: .133333rem;
}
</style>
